import { Component, ElementRef, ViewChild, OnInit, Input, EventEmitter, AfterViewInit, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { RouterModule, ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TabsComponent } from '../tabs/tabs.component';
import { NavLinksService } from '../service/nav-links.service';
import { UserService } from '../service/user.service';
import { SidebarService } from '../service/sidebar.service';
import { MenuService } from '../service/menu.service';
import { Subscription } from 'rxjs';
import { StateService } from '../service/state.service';
import { LoginService } from '../service/login.service';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [RouterModule, CommonModule, TabsComponent],
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit, AfterViewInit,OnChanges {
  public navLinks: any[] = [];
  @Input() sidebarToggle?: EventEmitter<void>;
  sidebarToggleSubscription?: Subscription;
  userId: number = 0;
  Role: string = '';
  currentRoute?: string;
  CustomerId: number = 0;
  firstName?: string;
  lastName?: string;
  ClientId?: string;
  clientName?: string;
  SpID!:any;
  sidebarActive = true;
  toggleimg = '../assets/vectors/SidebarToggleoff.svg'
  logoImageUrl = './assets/Images/TeamLeaseRegtech_Logo_new.png';
  logoImageClass = 'nav-open-logo';
  svgIcon = `../assets/vectors/Reports.svg`;
  isFirstLogin = true; 
  CustomerName = '';
  isActive:boolean = false;
 subscription!: Subscription;
  constructor(
    private navLinksService: NavLinksService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private sidebarService: SidebarService,
    private cdr: ChangeDetectorRef,
    private menuService: MenuService,
    private stateService: StateService
  
  ) { }

  @ViewChild('sidebar', { static: true }) sidebar!: ElementRef;

  ngOnInit(): void {
    debugger;
    this.firstName = this.userService.getFirstName();
    this.lastName = this.userService.getLastName();
    this.userId = this.userService.getUserId();
    this.Role = this.userService.getRole();
    this.CustomerId = this.userService.getCustomerId();
    this.ClientId = this.userService.getClientId();
    this.CustomerName = this.userService.getClientName();
  
    this.SpID=this.userService.getServiceProviderID();
    console.log('sp',this.SpID);
    
    if( this.currentRoute=='/CustomerMasters' || this.currentRoute == "/UserMaster"){
      this.sidebarActive=false;
    }
    this.currentRoute = this.router.url;
    if (this.isFirstLogin ) {
      this.toggleSidebar(true);
      this.isFirstLogin = false; 
    }
  
    if (this.Role === 'LSA' || this.Role==='BT' || this.Role ==='LFT' || this.Role==='RCP' || this.Role==='SDEXE' || this.Role==='CORDN' || this.Role==='SME'||this.Role=='HMGR'||this.Role=='HAPPR') {
      this.sidebarActive = true; 
      this.loadSidebarLinks();
      console.log('Side bar links',this.loadSidebarLinks());
        this.toggleSidebar(false);
    }
    

    this.router.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {
          debugger;
          this.currentRoute = this.router.url;
          if (this.currentRoute !== '/EntityDashboard'  && this.currentRoute !== '/CustomerMasters'  && this.currentRoute !== '/UserMaster' && this.Role!=='SDEXE' && this.Role!=='RCP' && this.Role!=='CORDN' && 
            this.Role!=='SME' && this.currentRoute !== '/EntityMasters' && this.currentRoute !== '/ActivityCalenders'  && this.currentRoute !== '/AgreementDetail' 
            && this.currentRoute !== '/CommercialMappings' && this.currentRoute !== '/MasterScopings'){
            this.loadSidebarLinks();
            this.toggleSidebar(false);
            if(this.userService.getClientId()&& this.currentRoute!='/ClientDashboard'){
              this.ClientId = `[${this.userService.getClientId()}]`;
              this.CustomerName = this.userService.getClientName();
            }
          }
          else if(this.Role==='SDEXE' || this.Role==='RCP' || this.Role==='CORDN' || this.Role==='SME'){
            this.loadSidebarLinks();
            this.toggleSidebar(false);
            this.CustomerName = '';
            this.ClientId = '';
          } 
          // else if(this.currentRoute=='/ClientDashboard') {
          //   debugger
          //   this.loadSidebarLinks();
          //   this.toggleSidebar(true);
          //   this.CustomerName = '';
          //   this.ClientId = '';
          // }
          else {
            this.navLinks = [];
            this.toggleSidebar(true);
            this.CustomerName = '';
            this.ClientId = '';
          }
          if( this.currentRoute!=='/DocumentGeneration' && this.currentRoute!='/Workspace' ){
            sessionStorage.removeItem('month');
            sessionStorage.removeItem('year');
            sessionStorage.removeItem('Input');
            sessionStorage.removeItem('complianceType');
            sessionStorage.removeItem('JsonId');
            sessionStorage.removeItem('formValues');
          }
          this.cdr.detectChanges();
        }
        if (['LSA', 'CORDN','BT','LFT'].includes(this.Role)) {
          this.ClientId = '';
          this.CustomerName = '';
        }
        this.cdr.detectChanges();
      }
      
    );
    
    // if(this.currentRoute=='/ClientDashboard' ){
    //   this.toggleSidebar(false);
    //   this.sidebarActive = true; 
    //   this.loadSidebarLinks();
    //   // this.isFirstLogin = false; 
    // }
    if (this.isFirstLogin) {
      this.toggleSidebar(true); 
      this.isFirstLogin = false;
    }
    this.subscription = this.stateService.isEnabled$.subscribe((value: boolean) => {
      this.isActive = value;
    });
    // let data = sessionStorage.getItem('isEnabled');
    // this.isActive = data=='true' ? true : false; 
    this.cdr.detectChanges();
    console.log('isActive', this.isActive);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.sidebarToggleSubscription = this.sidebarService.sidebarToggle$.subscribe(() => {
        this.toggleSidebar(!this.sidebarActive);
      });
    }, 0);
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.SpID=this.userService.getServiceProviderID();
    this.currentRoute=='/ClientDashboard'? this.ClientId = '' :'';
    this.currentRoute=='/ClientDashboard'? this.CustomerName = '':'';
  }
  ngOnDestroy() {
    if (this.sidebarToggleSubscription) {
      this.sidebarToggleSubscription.unsubscribe();
      this.subscription!.unsubscribe();
    }
  }
  gotoEntityDashboard(){
    this.ClientId = '';
    this.CustomerName = '';
    this.router.navigate(['/EntityDashboard']);
  }
  logOut(){
    this.ClientId = '';
    this.CustomerName = '';
    this.userService.clearUserData();
    this.router.navigate(['/Login']);
  }
  loadSidebarLinks() {
    debugger;

    if (this.Role && this.CustomerId && this.SpID) {
      this.navLinksService.getMenuBasedOnRole(this.Role, this.CustomerId, this.SpID).subscribe({
        next: (response) => {
          if (response.status === 200) {
            this.navLinks = response.body; 
          }
        },
        error: (error) => {
          console.error('Error fetching entity data', error);
        }
      });
    } else {
      console.warn("Required parameters missing for loading sidebar links.");
    }
  }

  toggleSidebar(open: boolean) {
    if (this.sidebar.nativeElement) {
      const sidebarElement = this.sidebar.nativeElement as HTMLElement;
      if (open) {
        sidebarElement.classList.add('active');              
      } else {
        sidebarElement.classList.remove('active');            
      }

      this.sidebarActive = open; 

      if (this.sidebarActive) {
        this.toggleimg = '../assets/vectors/SidebarToggleoff.svg'
        this.logoImageUrl = './assets/Images/TeamLease-small.png';
        this.logoImageClass = 'nav-close-logo';
      } else {
        this.toggleimg = '../assets/vectors/SidebarToggle.svg'
        this.logoImageUrl = './assets/Images/TeamLeaseRegtech_Logo_new.png';
        this.logoImageClass = 'nav-open-logo';
      }

      const sidebarBtn = document.querySelector('.sidebarBtn') as HTMLElement;
      if (sidebarBtn) {
        if (this.currentRoute === '/EntityDashboard'||this.currentRoute ==='/CustomerMaster' || this.currentRoute === '/UserMaster') {
          this.ClientId = '';
          this.CustomerName = '';
          if (sidebarElement.classList.contains('active')) {
            sidebarBtn.classList.replace('bx-menu-alt-right', 'bx-menu');
          } else {
            sidebarBtn.classList.replace('bx-menu', 'bx-menu-alt-right');
          }
        } else {
          sidebarBtn.classList.replace('bx-menu', 'bx-menu-alt-right');
        }
      }

      const containerContent = document.querySelector('.container-content') as HTMLElement;
      if (containerContent) {
        if (sidebarElement.classList.contains('active')) {
          containerContent.classList.add('sidebar-active');
        } else {
          containerContent.classList.remove('sidebar-active');
        }
      }
    }
  }

  onSidebarLinkClick(linkName: string) {
    this.menuService.setSelectedMenu(linkName);
    console.log('Selected Sidebar',linkName);
    
  }

}
