import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class NavLinksService {

    private apiUrl = environment.apiUrl;

    private token:string;
    constructor(private http: HttpClient,private userService:UserService) {
      this.token = this.userService.getAccessToken();
    }

    getMenuBasedOnRole(Role: string, CustomerId: number, ServiceProvider: number): Observable<HttpResponse<any>> {
        const url = `${this.apiUrl}/api/MenuHandler/GetMenuBasedOnRole?Role=${Role}&CustomerId=${CustomerId}&ServiceProvider=${ServiceProvider}`;
        const headers = new HttpHeaders({
            'Authorization': `Bearer ${this.userService.getAccessToken()}`, 
          });
        return this.http.get(url, { headers: headers,observe: 'response' });
    }
}
