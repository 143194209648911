import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private roleIdKey: string = 'roleId';
    private roleKey: string = 'role';
    private userIdKey: string = 'userId';
    private firstNameKey: string = 'firstName';
    private lastNameKey: string = 'lastName';
    private customerIdKey: string = 'customerId';
    private customerNameKey: string = 'customerName';
    private clientNameKey: string = 'clientName';
    private clientIdKey: string = 'clientId';
    private accessTokenKey:string = 'accesstoken';
    private ServiceProviderIDKey:string = 'ServiceProviderID';

    constructor() { }

    setUserId(UserId: number): void {
        sessionStorage.setItem(this.userIdKey, UserId.toString());
    }

    getUserId(): number {
        return parseInt(sessionStorage.getItem(this.userIdKey) || '0', 10);
    }

    setRoleId(roleId: number): void {
        sessionStorage.setItem(this.roleIdKey, roleId.toString());
    }

    getRoleId(): number {
        return parseInt(sessionStorage.getItem(this.roleIdKey) || '0', 10);
    }

    setRole(role: string): void {
        sessionStorage.setItem(this.roleKey, role);
    }

    getRole(): string {
        return sessionStorage.getItem(this.roleKey) || '';
    }

    setFirstName(FirstName: string): void {
        sessionStorage.setItem(this.firstNameKey, FirstName);
    }

    getFirstName(): string {
        return sessionStorage.getItem(this.firstNameKey) || '';
    }

    setLastName(LastName: string): void {
        sessionStorage.setItem(this.lastNameKey, LastName);
    }

    getLastName(): string {
        return sessionStorage.getItem(this.lastNameKey) || '';
    }

    setCustomerId(customerId: number): void {
        sessionStorage.setItem(this.customerIdKey, customerId.toString());
    }

    getCustomerId(): number {
        return parseInt(sessionStorage.getItem(this.customerIdKey) || '0', 10);
    }

    setCustomerName(customerName: string): void {
        sessionStorage.setItem(this.customerNameKey, customerName);
    }

    getCustomerName(): string {
        return sessionStorage.getItem(this.customerNameKey) || '';
    }

    setClientName(clientName: string): void {
        sessionStorage.setItem(this.clientNameKey, clientName);
    }

    getClientName(): string {
        return sessionStorage.getItem(this.clientNameKey) || '';
    }

    setClientId(clientId: string): void {
        sessionStorage.setItem(this.clientIdKey, clientId);
    }

    getClientId(): string {
        return sessionStorage.getItem(this.clientIdKey) || '';
    }

    getAccessToken():string {
        return sessionStorage.getItem(this.accessTokenKey) || '';
    }
    
    setAccessToken(accesstoken:string):void {
        sessionStorage.setItem(this.accessTokenKey, accesstoken);
    }

    getServiceProviderID(): number {
        return parseInt(sessionStorage.getItem(this.ServiceProviderIDKey) || '0', 10)      
    }

    setServiceProviderID(accesstoken: number): void {
        sessionStorage.setItem(this.ServiceProviderIDKey, accesstoken.toString());
    }

    clearUserData(): void {
        sessionStorage.removeItem(this.roleIdKey);
        sessionStorage.removeItem(this.roleKey);
        sessionStorage.removeItem(this.userIdKey);
        sessionStorage.removeItem(this.firstNameKey);
        sessionStorage.removeItem(this.lastNameKey);
        sessionStorage.removeItem(this.customerIdKey);
        sessionStorage.removeItem(this.customerNameKey);
        sessionStorage.removeItem(this.clientNameKey);
        sessionStorage.removeItem(this.clientIdKey);
        sessionStorage.removeItem(this.accessTokenKey);
        sessionStorage.removeItem(this.ServiceProviderIDKey);
    }
}
