import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
@Injectable({
  providedIn: 'root'
})
export class PrincipleEmployerService {
  private baseUrl = environment.apiUrl;
  private token:string;
  constructor(private http: HttpClient,private userService:UserService) {
    this.token = this.userService.getAccessToken();
   }
  getClraType(clientId:string): Observable<HttpResponse<any>>{
    let url = `${this.baseUrl}/api/Clra/Getclra_type?Avacom_EntityID=${clientId}`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.token}`, 
    });
    return this.http.get(url, { headers: headers,  observe: 'response' });
    
  }

  get_CAC_ClraData(entityId:string): Observable<HttpResponse<any>> {
    let url = `${this.baseUrl}/api/Clra/Get_Clra_Data?EntityID=${entityId}`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.token}`, 
    });
    return this.http.get(url, { headers: headers,observe: 'response' });
  }
  savePrincipleEmployer(payload:any): Observable<HttpResponse<any>> {
    let url = `${this.baseUrl}/api/Clra/SavePrincipleEmployerDetails`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.token}`, 
    });
    return this.http.post(url,payload, { headers: headers,observe: 'response' });
  }

  GetPrincipleEmployerDetails(id:any): Observable<HttpResponse<any>> {
    let url = `${this.baseUrl}/api/Clra/GetPrincipleEmployerDetails?ID=${id}`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.token}`, 
    });
    return this.http.get(url, { headers: headers,observe: 'response' });
  }
  DeletePrincipleEmployer(peid:any,userId:any): Observable<HttpResponse<any>> {
    let url = `${this.baseUrl}/api/Clra/DeletePrincipleEmployer?PEID=${peid}&UserID=${userId}`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.token}`, 
    });
    return this.http.get(url, {headers: headers, observe: 'response' });
  }

  uploadHolidayMaster(file: File, entityId: string,userId:any,customerId:any): Observable<HttpResponse<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('ClientID', entityId);
    formData.append('UserID', userId);
    formData.append('CustomerID', customerId);
    let url = `${this.baseUrl}/api/Clra/HolidayMasterUpload_Clra`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.token}`, 
    });
    return this.http.post(url,formData, {headers: headers, observe: 'response' });
  }

  exportToExcel(entityId:string): Observable<HttpResponse<any>> {
    let url = `${this.baseUrl}/api/Clra/CLRAPEReport_ExportToExcel?EntityID=${entityId}`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.token}`, 
    });
    return this.http.get(url, { headers: headers,  observe: 'response' });
    
  }

  peMasterUpload(file: File, entityId: string,userId:any,customerId:any): Observable<HttpResponse<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('ClientID', entityId);
    formData.append('UserID', userId);
    formData.append('CustomerID', customerId);
    let url = `${this.baseUrl}/api/Clra/PEMasterUpload`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.token}`, 
    });
    return this.http.post(url,formData, {headers: headers, observe: 'response' });
  }

  getClraCAPData(entityId:string): Observable<HttpResponse<any>> {
    let url = `${this.baseUrl}/api/Clra/Get_CAPClra_Data?EntityID=${entityId}`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.token}`, 
    });
    return this.http.get(url, { headers: headers,  observe: 'response' });
    
  }

  GetPrincipleEmployerDetailsCAP(id:any): Observable<HttpResponse<any>> {
    let url = `${this.baseUrl}/api/Clra/GetPrincipleEmployerDetailsCAP?ID=${id}`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.token}`, 
    });
    return this.http.get(url, { headers: headers,  observe: 'response' });
    
  }

  getSampleHolidayFile(customerId:any,userId:any,entityId: any): Observable<HttpResponse<any>> {
    let url = `${this.baseUrl}/api/Clra/SampleHolidayFile?CustomerId=${customerId}&UserId=${userId}&EntityId=${entityId}`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.token}`, 
    });
    return this.http.get(url, { headers: headers,observe: 'response' });
  }
}
