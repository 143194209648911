<div class="sidebar" #sidebar>
    <div class="logo-details">
        <img [src]="logoImageUrl" [class]="logoImageClass" alt="TeamLease Logo">
        <span class="logo_name"></span>
    </div>
    <ul class="nav-links">
        <li *ngFor="let link of navLinks">
            <a [routerLink]="link.hrefurl" [ngClass]="{'active': link.name === 'Dashboard'}"
                (click)="onSidebarLinkClick(link.name)">
                <!-- <i [class]="link.icon"></i> -->
                <img [src]="'../assets/vectors/'+link.icon+'.svg'" alt="">
                <!-- <img src="../assets/vectors/Regulatory_Abstracts.svg"  alt=""> -->
                <span class="links_name">{{ link.name }}</span>
            </a>
        </li>
    </ul>
</div>
<section class="home-section">
    <nav class="navbar-fluid"
        style="display: flex; justify-content: space-between; align-items: center; position: relative; width: 100%; left: 0;">

        <div class="sidebar-button" style="color: #fff;">
            <i class="menu-btn" (click)="toggleSidebar(!sidebarActive)"><img style="width: 25px;" [src]="toggleimg"></i>
            <span class="dashboard" *ngIf="currentRoute!=='/ClientDashboard'" style="font-size: 15px;">{{CustomerName}}{{ClientId}}</span>
        </div>
        <div class="navbar-end" style="display: flex; align-items: center;">
            <span class="user-name" style="margin-right: 20px;color: white;">
                {{ firstName }} {{ lastName }}
            </span>
            <i class="bx " title="Log Out" style="color: #fff; font-size: 24px; margin-right: 10px;"><img style="width: 35px;" (click)="logOut()"
                    src="../assets/vectors/Profile.svg"></i>
            <i class="bx " title="Switch Entity"
                *ngIf="Role!='CORDN' && Role!='SME' && Role!='BT' && Role !='LSA' && Role!='SDEXE' && Role!='HMGR' && currentRoute!='/EntityDashboard'"
                style="color: #fff; font-size: 24px; "><img style="width: 35px;" (click)="gotoEntityDashboard()"
                    src="../assets/vectors/TransferNav.svg"></i>

        </div>
    </nav>
    <br />
    <div class="container-fluid">
        <div class="container-content">
            <!-- height: 89vh; -->
            <div class="container-fluid ">
                <ng-container
                    *ngIf="(currentRoute !== '/EntityDashboard'  && currentRoute !== '/CustomerMasters' && currentRoute !== '/ComplianceDashboard' && currentRoute !== '/UserMaster'
                     && currentRoute !== '/Notices'&& currentRoute !== '/DocumentGeneration' && currentRoute !== '/OutputChallanCustomer' && currentRoute !== '/OutputChallanPerformer'
                     && currentRoute !== '/LicenseRequestDetails' && currentRoute !== '/RCPTeam'  && currentRoute !== '/SDExecutor'  && currentRoute !== '/DocumentSection' 
                     && currentRoute !== '/EntityMasters' && currentRoute !== '/ActivityCalenders'  && currentRoute !== '/AgreementDetail' && currentRoute !== '/CommercialMappings' 
                     && currentRoute !== '/MasterScopings'&& currentRoute !== '/CustomerMaster'&& currentRoute !== '/MasterScoping'&& currentRoute !== '/EntityMaster'
                     && currentRoute !== '/ActivityCalender'  && currentRoute !== '/AgreementDetails' && currentRoute !== '/CommercialMapping' && currentRoute !== '/MasterScopingSetup' 
                     && currentRoute !== '/NoticeDetails' && currentRoute !== '/SMEResponse' && currentRoute !== '/SDEXENotice' && currentRoute !== '/NoticeDocumentSection'
                     && currentRoute!=='/ClientEntityDashboard'&& currentRoute!=='/ClientDashboard'&& currentRoute!=='/ClientEntityDashboard' && currentRoute!=='/ClientEntityDashboardGrid')">
                    <app-tabs></app-tabs>
                    <span *ngIf="isActive && currentRoute!=='/ClientDashboard'">
                        <hr class="hr-fluid">
                    </span>
                </ng-container>
            </div>

            <!-- <div class="mb-4"></div> -->
            <router-outlet></router-outlet>
        </div>
    </div>
</section>