import { Routes } from '@angular/router';

export const appRoutes: Routes = [
    { path: '', redirectTo: '/Login', pathMatch: 'full' },
    {
        path: 'Login',
        loadComponent: () => import('./login/login.component').then(m => m.LoginComponent)
    },
    {
        path: 'EntityDashboard',
        loadComponent: () => import('./pages/entitydashboard/entitydashboard.component').then(m => m.EntitydashboardComponent),
    },
    {
        path: 'ComplianceDashboard',
        loadComponent: () => import('./pages/compliance-dashboard/compliance-dashboard.component').then(m => m.ComplianceDashboardComponent),
    },
    {
        path: 'ClientDashboard',
        loadComponent: () => import('./pages/client-dashboard/client-dashboard/client-dashboard.component').then(m => m.ClientDashboardComponent),
    },
    {
        path: 'Workspace',
        loadComponent: () => import('./pages/workspace/workspace.component').then(m => m.WorkspaceComponent),
    },
    {
        path: 'DispenceryMaster',
        loadComponent: () => import('./pages/esic-tic/dispencery-master/dispencery-master.component').then(m => m.DispenceryMasterComponent),
    },
    {
        path: 'SubcodeMaster',
        loadComponent: () => import('./pages/esic-tic/esic-subcode/esic-subcode.component').then(m => m.EsicSubcodeComponent),
    },
    {
        path: 'ESICInputs',
        loadComponent: () => import('./pages/esic-tic/esicinputs/esicinputs.component').then(m => m.ESICInputsComponent),
    },
    {
        path: 'ESICReport',
        loadComponent: () => import('./pages/esic-tic/esicreport/esicreport.component').then(m => m.ESICReportComponent),
    },
    {
        path: 'LocationMaster',
        loadComponent: () => import('./pages/manage-masters/location-master/location-master.component').then(m => m.LocationMasterComponent),
    },
    {
        path: 'DesignationMaster',
        loadComponent: () => import('./pages/manage-masters/designation-master/designation-master.component').then(m => m.DesignationMasterComponent),
    },
    {
        path: 'LeaveTypeMaster',
        loadComponent: () => import('./pages/manage-masters/leave-type-master/leave-type-master.component').then(m => m.LeaveTypeMasterComponent),
    },
    {
        path: 'StatutoryMaster',
        loadComponent: () => import('./pages/manage-masters/statutory-master/statutory-master.component').then(m => m.StatutoryMasterComponent),
    },
    {
        path: 'ChallanPTSlab',
        loadComponent: () => import('./pages/manage-masters/challan-pt-slab/challan-pt-slab.component').then(m => m.ChallanPtSlabComponent),
    },
    {
        path: 'PayCodeMapping',
        loadComponent: () => import('./pages/manage-masters/paycode-master/paycode-master.component').then(m => m.PaycodeMasterComponent),
    },
    {
        path: 'MinimumWagesPeriod',
        loadComponent: () => import('./pages/minimum-wages/minimum-wages-period/minimum-wages-period.component').then(m => m.MinimumWagesPeriodComponent),
    },
    {
        path: 'IndustrySkillDesignationMapping',
        loadComponent: () => import('./pages/minimum-wages/industry-skill-designation-mapping/industry-skill-designation-mapping.component').then(m => m.IndustrySkillDesignationMappingComponent),
    },
    {
        path: 'ZoneLocationMapping',
        loadComponent: () => import('./pages/minimum-wages/zone-location-mapping/zone-location-mapping.component').then(m => m.ZoneLocationMappingComponent),
    },
    {
        path: 'Notifications',
        loadComponent: () => import('./pages/minimum-wages/notifications/notifications.component').then(m => m.NotificationsComponent),
    },
    {
        path: 'MinimumWages',
        loadComponent: () => import('./pages/minimum-wages/minimum-wages/minimum-wages.component').then(m => m.MinimumWagesComponent),
    },
    {
        path: 'ManageEmployee',
        loadComponent: () => import('./pages/onboard-employee/manage-employee/manage-employee.component').then(m => m.ManageEmployeeComponent),
    },
    {
        path: 'EmployeeMasterUpload',
        loadComponent: () => import('./pages/onboard-employee/employee-master-upload/employee-master-upload.component').then(m => m.EmployeeMasterUploadComponent),
    },
    {
        path: 'EmployeeMasterReport',
        loadComponent: () => import('./pages/onboard-employee/employee-report/employee-report.component').then(m => m.EmployeeReportComponent),
    },
    {
        path: 'EmployeeCTCMaster',
        loadComponent: () => import('./pages/onboard-employee/employee-ctc/employee-ctc.component').then(m => m.EmployeeCtcComponent),
    },
    {
        path: 'PremiseMaster',
        loadComponent: () => import('./pages/onboard-entity/premise-master/premise-master.component').then(m => m.PremiseMasterComponent),
    },
    {
        path: 'UserBranchMapping',
        loadComponent: () => import('./pages/onboard-entity/user-branch-mapping/user-branch-mapping.component').then(m => m.UserBranchMappingComponent),
    },
    {
        path: 'AddNotice',
        loadComponent: () => import('./pages/notices/addnotices/addnotices.component').then(m => m.AddnoticesComponent),
    },
    {
        path: 'ComplianceAssignment',
        loadComponent: () => import('./pages/onboard-entity/compliance-assignment/compliance-assignment.component').then(m => m.ComplianceAssignmentComponent),
    },
    {
        path: 'ManageCompliances',
        loadComponent: () => import('./pages/onboard-entity/manage-compliances/manage-compliances.component').then(m => m.ManageCompliancesComponent),
    },
    {
        path: 'CustomerMaster',
        loadComponent: () => import('./pages/onboard-entity/customer-master/customer-master.component').then(m => m.CustomerMasterComponent),
    },
    {
        path: 'EntityMaster',
        loadComponent: () => import('./pages/onboard-entity/entity-master/entity-master.component').then(m => m.EntityMasterComponent),
    },
    {
        path: 'ActivityCalender',
        loadComponent: () => import('./pages/master-scoping/activity-calender/activity-calender.component').then(m => m.ActivityCalenderComponent),
    },
    {
        path: 'AgreementDetails',
        loadComponent: () => import('./pages/master-scoping/agreement-details-master/agreement-details-master.component').then(m => m.AgreementDetailsMasterComponent),
    },
    {
        path: 'MasterScoping',
        loadComponent: () => import('./pages/master-scoping//master-scoping-setup//master-scoping-setup.component').then(m => m.MasterScopingSetupComponent),
    },
    {
        path: 'CommercialMapping',
        loadComponent: () => import('./pages/master-scoping/commercial-mapping/commercial-mapping.component').then(m => m.CommercialMappingComponent),
    },
    {
        path: 'ReviewRegulatoryAbstracts',
        loadComponent: () => import('./pages/abstract/review-regulatory-abstracts/review-regulatory-abstracts.component').then(m => m.ReviewRegulatoryAbstractsComponent),
    },
    {
        path: 'Master_Abstracts',
        loadComponent: () => import('./pages/abstract/master-abstracts/master-abstracts.component').then(m => m.MasterAbstractsComponent),
    },
    {
        path: 'LabourDepartmentAddress',
        loadComponent: () => import('./pages/abstract/labour-department-address/labour-department-address.component').then(m => m.LabourDepartmentAddressComponent),
    },
    {
        path: 'ActivityCalender',
        loadComponent: () => import('./pages/master-scoping/activity-calender/activity-calender.component').then(m => m.ActivityCalenderComponent),
    },
    {
        path: 'UserMaster',
        loadComponent: () => import('./pages/onboard-entity/user-master/user-master.component').then(m => m.UserMasterComponent),
    },
    {
        path: 'ComplianceAssignmentReport',
        loadComponent: () => import('./pages/reports/compliance-assignment-report/compliance-assignment-report.component').then(m => m.ComplianceAssignmentReportComponent),
    },
    {
        path: 'LCMyComplianceDocumentComponent',
        loadComponent: () => import('./pages/statutorydocument/my-compliance-document/my-compliance-document.component').then(m => m.MyComplianceDocumentComponent),
    },
    {
        path: 'ComplianceStatusReport',
        loadComponent: () => import('./pages/reports/compliance-status-report/compliance-status-report.component').then(m => m.ComplianceStatusReportComponent),
    },
    {
        path: 'DigitalSignature',
        loadComponent: () => import('./pages/my-documents/digital-signature/digital-signature.component').then(m => m.DigitalSignatureComponent),
    },
    {
        path: 'VarianceReport',
        loadComponent: () => import('./pages/reports/variance-report/variance-report.component').then(m => m.VarianceReportComponent),
    },
    {
        path: 'Notices',
        loadComponent: () => import('./pages/notices/notices/notices.component').then(m => m.NoticesComponent),
    },
    {
        path: 'ECRMaster',
        loadComponent: () => import('./pages/ecr/ecr-master/ecr-master.component').then(m => m.EcrMasterComponent),
    },
    {
        path: 'CLRAAssignment',
        loadComponent: () => import('./pages/reports/clra-assignment-report/clra-assignment-report.component').then(m => m.CLRAAssignmentReportComponent),
    },
    {
        path: 'DocumentGeneration',
        loadComponent: () => import('./pages/workspace/document-generation/document-generation.component').then(m => m.DocumentGenerationComponent),
    },
    {
        path: 'EcrConfirmation',
        loadComponent: () => import('./pages/ecr/ecr-confirmation/ecr-confirmation.component').then(m => m.EcrConfirmationComponent),
    },
    {
        path: 'ClientRegistrationRepository',
        loadComponent: () => import('./pages/my-documents/client-registration-repository/client-registration-repository.component').then(m => m.ClientRegistrationRepositoryComponent),
    },
    {
        path: 'RegistrationRepository',
        loadComponent: () => import('./pages/my-documents/registration-repository/registration-repository.component').then(m => m.RegistrationRepositoryComponent),
    },
    {
        path: 'ClientCommercialSetup',
        loadComponent: () => import('./pages/invoice-files/Setup/client-commercial-setup/client-commercial-setup.component').then(m => m.ClientCommercialSetupComponent),
    },
    {
        path: 'ClientStatutoryDocument',
        loadComponent: () => import('./pages/statutorydocument/client-statutory-document/client-statutory-document.component').then(m => m.ClientStatutoryDocumentComponent),
    },
    {
        path: 'LC_MIS',
        loadComponent: () => import('./pages/mis-document/mis-document/mis-document.component').then(m => m.MisDocumentComponent),
    },
    {
        path: 'InvoiceDetails',
        loadComponent: () => import('./pages/invoice-files/invoice-details/invoice-details.component').then(m => m.InvoiceDetailsComponent),
    },
    {
        path: 'LC_ESIC',
        loadComponent: () => import('./pages/esic-tic/lc-esic/lc-esic.component').then(m => m.LCESICComponent),
    },
    {
        path: 'PODetails',
        loadComponent: () => import('./pages/invoice-files/po-details/po-details.component').then(m => m.PoDetailsComponent),
    },
    {
        path: 'CriticalDocuments',
        loadComponent: () => import('./pages/critical-documents/critical-documents/critical-documents.component').then(m => m.CriticalDocumentsComponent),
    },
    {
        path: 'NoticeClientPortal',
        loadComponent: () => import('./pages/notices/notice-client-portal/notice-client-portal.component').then(m => m.NoticeClientPortalComponent),
    },
    {
        path: 'InputFilesClient',
        loadComponent: () => import('./pages/inputfiles/input-files-client/input-files-client.component').then(m => m.InputFilesClientComponent),
    },
    {
        path: 'InvoiceCreation',
        loadComponent: () => import('./pages/invoice-files/invoice-creation/invoice-creation.component').then(m => m.InvoiceCreationComponent),
    },
    {
        path: 'GstUpload',
        loadComponent: () => import('./pages/invoice-files/gst-upload/gst-upload.component').then(m => m.GstUploadComponent),
    },
    {
        path: 'LC_MyReportsMonthly',
        loadComponent: () => import('./pages/compliance-summary/lc-my-reports-monthly/lc-my-reports-monthly.component').then(m => m.LCMyReportsMonthlyComponent),
    },
    {
        path: 'LC_MyReportsOnboarding',
        loadComponent: () => import('./pages/compliance-summary/lc-my-reports-onboarding/lc-my-reports-onboarding.component').then(m => m.LCMyReportsOnboardingComponent),
    },
    {
        path: 'InvoiceGstUploadApproval',
        loadComponent: () => import('./pages/invoice-files/gst-approval/gst-approval.component').then(m => m.GstApprovalComponent),
    },
    {
        path: 'InvoiceReport',
        loadComponent: () => import('./pages/invoice-files/invoice-report/invoice-report.component').then(m => m.InvoiceReportComponent),
    },
    {
        path: 'OutputChallanCustomer',
        loadComponent: () => import('./pages/inputfiles/output-challan-customer/output-challan-customer.component').then(m => m.OutputChallanCustomerComponent),
    },
    {
        path: 'InputFilesPerformer',
        loadComponent: () => import('./pages/inputfiles/input-files-performer/input-files-performer.component').then(m => m.InputFilesPerformerComponent),
    },
    {
        path: 'OutputChallanPerformer',
        loadComponent: () => import('./pages/inputfiles/output-challan-performer/output-challan-performer.component').then(m => m.OutputChallanPerformerComponent),
    },
    {
        path: 'InvoiceFaceSheet',
        loadComponent: () => import('./pages/invoice-files/invoice-face-sheet/invoice-face-sheet.component').then(m => m.InvoiceFaceSheetComponent),
    },
    {
        path: 'AnchorDashboardSummary',
        loadComponent: () => import('./pages/inputfiles/anchor-dashboard-summaries/anchor-dashboard-summaries/anchor-dashboard-summaries.component').then(m => m.AnchorDashboardSummariesComponent),
    },
    {
        path: 'InvoiceApproval',
        loadComponent: () => import('./pages/invoice-files/invoice-approval/invoice-approval.component').then(m => m.InvoiceApprovalComponent),
    },
    {
        path: 'CreditNote',
        loadComponent: () => import('./pages/invoice-files/creditnote/creditnote.component').then(m => m.CreditnoteComponent),
    },
    {
        path: 'InvoiceDraftToRegular',
        loadComponent: () => import('./pages/invoice-files/invoice-draft-to-regular/invoice-draft-to-regular.component').then(m => m.InvoiceDraftToRegularComponent),
    },
    {
        path: 'AgreementDetails',
        loadComponent: () => import('./pages/master-scoping/agreement-details-master/agreement-details-master.component').then(m => m.AgreementDetailsMasterComponent),
    },
    {
        path: 'CommercialMapping',
        loadComponent: () => import('./pages/master-scoping/commercial-mapping/commercial-mapping.component').then(m => m.CommercialMappingComponent),
    },
    {
        path: 'MasterScopingSetup',
        loadComponent: () => import('./pages/master-scoping/master-scoping-setup/master-scoping-setup.component').then(m => m.MasterScopingSetupComponent),
    },
    {
        path: 'InvoiceUpdation',
        loadComponent: () => import('./pages/invoice-files/invoice-updation/invoice-updation.component').then(m => m.InvoiceUpdationComponent),
    },
    {
        path: 'PrincipleEmployer',
        loadComponent: () => import('./pages/onboard-clra/principle-employer/principle-employer.component').then(m => m.PrincipleEmployerComponent),
    },
    {
        path: 'EmployerLocation',
        loadComponent: () => import('./pages/onboard-clra/employer-location/employer-location.component').then(m => m.EmployerLocationComponent),
    },
    {
        path: 'CreditNoteApproval',
        loadComponent: () => import('./pages/invoice-files/creditnote-approval/creditnote-approval.component').then(m => m.CreditnoteApprovalComponent),
    },
    {
        path: 'Contractor',
        loadComponent: () => import('./pages/onboard-clra/principle-employer-contractor-creation/principle-employer-contractor-creation.component').then(m => m.PrincipleEmployerContractorCreationComponent),
    },
    {
        path: 'PrincipleEmployeeMasterCreation',
        loadComponent: () => import('./pages/onboard-clra/principle-employee-master-creation/principle-employee-master-creation.component').then(m => m.PrincipleEmployeeMasterCreationComponent),
    },
    {
        path: 'NewRegistrationLicense',
        loadComponent: () => import('./pages/registration-licenses/new-registration-license/new-registration-license.component').then(m => m.NewRegistrationLicenseComponent),
    },
    {
        path: 'ExistingRegistrationLicense',
        loadComponent: () => import('./pages/registration-licenses/existing-registration-license/existing-registration-license.component').then(m => m.ExistingRegistrationLicenseComponent),
    },
    {
        path: 'LicenseRequestDetails',
        loadComponent: () => import('./pages/registration-licenses/license-request-details/license-request-details.component').then(m => m.LicenseRequestDetailsComponent),
    },
    {
        path: 'RCPTeam',
        loadComponent: () => import('./pages/registration-licenses/rcp-team/rcp-team.component').then(m => m.RcpTeamComponent),
    },
    {
        path: 'SDExecutor',
        loadComponent: () => import('./pages/registration-licenses/sd-executor/sd-executor.component').then(m => m.SdExecutorComponent),
    },
    {
        path: 'DocumentSection',
        loadComponent: () => import('./pages/registration-licenses/document-section/document-section.component').then(m => m.DocumentSectionComponent),
    },
    {
        path: 'AgreementDetail',
        loadComponent: () => import('./pages/master-scoping/agreement-details-master/agreement-details-master.component').then(m => m.AgreementDetailsMasterComponent),
    },
    {
        path: 'EntityMasters',
        loadComponent: () => import('./pages/onboard-entity/entity-master/entity-master.component').then(m => m.EntityMasterComponent),
    },
    {
        path: 'MasterScopings',
        loadComponent: () => import('./pages/master-scoping//master-scoping-setup//master-scoping-setup.component').then(m => m.MasterScopingSetupComponent),
    },
    {
        path: 'CommercialMappings',
        loadComponent: () => import('./pages/master-scoping/commercial-mapping/commercial-mapping.component').then(m => m.CommercialMappingComponent),
    },
    {
        path: 'ActivityCalenders',
        loadComponent: () => import('./pages/master-scoping/activity-calender/activity-calender.component').then(m => m.ActivityCalenderComponent),
    },
    {
        path: 'CustomerMasters',
        loadComponent: () => import('./pages/onboard-entity/customer-master/customer-master.component').then(m => m.CustomerMasterComponent),
    },
    {
        path: 'NoticeDetails',
        loadComponent: () => import('./pages/notices/edit-notice/edit-notice.component').then(m => m.EditNoticeComponent),
    },
    {
        path: 'SMEResponse',
        loadComponent: () => import('./pages/notices/smeresponse/smeresponse.component').then(m => m.SMEResponseComponent),
    },
    {
        path: 'SDEXENotice',
        loadComponent: () => import('./pages/notices/sdexecuter/sdexecuter.component').then(m => m.SDExecuterComponent),
    },
    {
        path: 'NoticeDocumentSection',
        loadComponent: () => import('./pages/notices/document-section/document-section.component').then(m => m.DocumentSectionComponent),
    },
    {
        path: 'ClientEntityDashboard',
        loadComponent: () => import('./pages/client-dashboard/client-entity-dashboard/client-entity-dashboard.component').then(m => m.ClientEntityDashboardComponent),
    },
    {
        path: 'ClientEntityDashboardGrid',
        loadComponent: () => import('./pages/client-dashboard/client-entity-dashboard-grid/client-entity-dashboard-grid.component').then(m => m.ClientEntityDashboardGridComponent),
    },
];
