import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private apiUrl = environment.apiUrl;

  private token:string;
  constructor(private http: HttpClient,private userService:UserService) {
    this.token = this.userService.getAccessToken();
  }

  validateUser(username: string, password: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/Auth/ValidateUser`;
    const body = {
      username: username,
      password: password
    };
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.token}`, 
    });
    return this.http.post(url, body, {headers: headers, observe: 'response' });
  }
  GetServiceProviderID(avacom_CustomerID: number): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/User/GetServiceProviderID?avacom_CustomerID=${avacom_CustomerID}`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.token}`,
    });
    return this.http.get(url, { headers: headers, observe: 'response' });
  }
}
