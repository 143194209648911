import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class TabsService {

  private apiUrl = environment.apiUrl;
  private selectedMenuSubject = new BehaviorSubject<string>('ESIC Cards');
  selectedMenu$ = this.selectedMenuSubject.asObservable();
  private selectedTabSubject = new BehaviorSubject<string>('');
  selectedTab$ = this.selectedTabSubject.asObservable();
  setSelectedMenu(menu: string) {
    this.selectedMenuSubject.next(menu);
  }
  private token:string;
  constructor(private http: HttpClient,private userService:UserService) {
    this.token = this.userService.getAccessToken();
  }

  getTabsDetails(ModuleName: string): Observable<HttpResponse<any>> {
    const url = `${this.apiUrl}/api/MenuHandler/GetTabsByModule?ModuleName=${ModuleName}`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.getAccessToken()}`, 
    });
    return this.http.get(url, { headers: headers,observe: 'response' });
  }

  setSelectedTab(tab: string): void {
    this.selectedTabSubject.next(tab);
  }

}
