import { Component, OnInit, Renderer2 } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { TabsService } from '../../app/service/tabs.service';
import { MenuService } from '../service/menu.service';
import { StateService } from '../service/state.service';
import { PrincipleEmployerService } from '../service/principle-employer.service';
import { UserService } from '../service/user.service';

interface Tab {
  tabName: string;
  routePath: string;
  moduleName: string;
  sort_order: number;
}

@Component({
  selector: 'app-tabs',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.css']
})
export class TabsComponent implements OnInit {
  role:any
  public tabs: Tab[] = [];
  sortedTabs: Tab[] = [];
  selectedTab?: string;
  selectedSidebar!: string;
  clraType:any
  constructor(
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private tabsService: TabsService,
    private menuService: MenuService,
    private stateService: StateService,
    private PEService:PrincipleEmployerService,
    private user:UserService
  ) { }

  ngOnInit(): void {
    this.role=this.user.getRole()
    this.user.getClientId()?this.getClraType():''
    this.menuService.selectedMenu$.subscribe((menu) => {
      this.selectedSidebar = menu || '';
      console.log('Current Sidebar Context:', this.selectedSidebar);
      this.fetchTabs(this.selectedSidebar);   
    });
    this.menuService.selectedMenu$.subscribe(menu => {
      this.fetchTabs(menu);
      console.log('Tabs',this.fetchTabs(menu));
      
    });
    

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      this.selectedTab = this.tabs.find(tab => tab.routePath === this.router.url)?.tabName;
      this.applySelectedTabClass();
    });
    const initialTab = this.tabs.find(tab => tab.routePath === this.router.url);
    if (initialTab) {
      this.selectedTab = initialTab.tabName;
      this.applySelectedTabClass();
    }
  }


  getClraType(){
    const clientId=this.user.getClientId()
    this.PEService.getClraType(clientId).subscribe({
      next:(response)=>{
        const data=response.body.result;
        this.clraType=data;

      }
    })
  }

  fetchTabs(menu: string): void {
    this.tabsService.getTabsDetails(menu).subscribe({
      next: (response) => {
        if (response) {
          this.tabs = response.body;
          console.log('Role', this.role);
          console.log('tabs', this.tabs);
          this.sortedTabs = this.tabs
            .filter(tab => {
              if (this.role === 'PERF' && this.selectedSidebar === 'Onboard Entity' ) {
                return tab.tabName === 'Premises' || tab.tabName === 'User-Branch Mapping';
              }
              return true;
            })
            .sort((a, b) => a.sort_order - b.sort_order)
            .filter((tab) => {
              return !(this.selectedSidebar === 'Onboard CLRA' && this.clraType === 'CAP' && tab.tabName === 'Employee Mapping');
            });
  
          const isEnabled = this.sortedTabs.length > 0;
          this.stateService.updateIsEnabled(isEnabled);
  
          const initialTab = this.tabs.find(tab => tab.routePath === this.router.url);
          if (initialTab) {
            this.selectedTab = initialTab.tabName;
            this.applySelectedTabClass();
          }
        }
      },
      error: (error) => {
        console.error('Error fetching Tabs data', error);
      }
    });
  }
  

  navigateTo(tab: Tab): void {
    this.selectedTab = tab.tabName;
    console.log('selected Tab',tab.tabName);
    this.router.navigate([tab.routePath]);
    this.applySelectedTabClass();  
  }

  isTabSelected(tab: Tab): boolean {
    return this.selectedTab === tab.tabName;
    
    
  }

  applySelectedTabClass(): void {
    setTimeout(() => { 
      const elements = document.querySelectorAll('.listItem');
      elements.forEach((element: Element) => {
        if (element.innerHTML.includes(this.selectedTab || '')) {
          this.renderer.addClass(element, 'selected-tab');
        } else {
          this.renderer.removeClass(element, 'selected-tab');
        }
      });
    }, 0);
  }
}
